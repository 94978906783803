import React from "react";
import {
  BACKGROUNDCHECK_INFORMATION,
  BADGE_PHOTO,
  I_9_DOCUMENT,
  ORDER_YOUR_SAFETY_SHOE,
  PRE_HIRE_ORIENTATION,
  REVIEW_FINAL_OFFER_LETTER,
  SCHEDULE_RIGHT_TO_WORK_APPOINTMENT,
} from "../../config/taskConfigConstants";
import { resolveDurationText } from "../../helpers/utils";
import { StatusEnum } from "../../utility/enums/common";
import { useChecklistArb } from "../../hooks/use-arb";
import { isCustomerService } from "../../utility/app-config-helper";
import { NHEAppointmentStatusEnum } from "../appointment-card/card-controlled";
import { CAAS_STATUS, GB_CAAS_STATUS } from "../i9-task/i9-data";
import ProgressRing from "../../assets/svg/progress-ring.svg";
import { useStatusText } from "./useStatusText.hook";
import { TaskCardActionItemContent, TaskCardActionItemDefaultContent } from "./task-card-action-item-content";
import { getCountryForDomain } from "../../utility/locale-helper";
import { SUPPORTED_COUNTRY } from "../../config/appConfigConstants";

interface CardTextContentProps {
  taskTitleText: string;
  taskName?: string;
  status?: string;
  shouldOverrideStatus?: Record<string, StatusEnum>;
  duration?: number;
  applicationId: string;
}
export const TaskCardTextContent = ({
  taskTitleText,
  taskName,
  status,
  shouldOverrideStatus,
  duration,
  applicationId,
}: CardTextContentProps) => {
  const bundle = useChecklistArb();
  const country = getCountryForDomain();

  const translatedApprovalPending = bundle.getMessage("checklist-General-Approval-Pending");
  const timeComplete = bundle.formatMessage("checklist-General-Mins", {
    minutes: duration,
  });

  const actionRequireAtAppointmentSection2 = bundle.getMessage(
    "checklist-General-TaskStatus-ActionRequiredAtAppointment-PendingSection2"
  );
  const actionRequireAtAppointmentSection3 = bundle.getMessage(
    "checklist-General-TaskStatus-ActionRequiredAtAppointment-PendingSection3"
  );
  const actionRequireSection1CompleteForCustomerService = bundle.getMessage(
    "checklist-US-Customer-Service-TaskStatus-Section1Complete"
  );
  const scheduledMessage = bundle.getMessage("checklist-General-TaskStatus-Reserved");
  const completedMessage = bundle.getMessage("checklist-General-TaskStatus-Completed");

  const overrideStatus =
    status && shouldOverrideStatus && shouldOverrideStatus[status] ? shouldOverrideStatus[status] : status;

  const statusText = useStatusText(overrideStatus, taskName);
  const resolveDurationTextMessage = resolveDurationText(timeComplete, status, duration, country);

  if (overrideStatus == StatusEnum.COMPLETED) {
    const completedTask = <TaskCardActionItemContent taskTitleText={taskTitleText} />;

    switch (taskName) {
      case BADGE_PHOTO:
        return (
          <>
            {completedTask}
            {status === StatusEnum.IN_PROGRESS && (
              <TaskCardActionItemContent
                imageOrText={<img width={"24px"} height={"24px"} src={ProgressRing} alt={""} />}
                actionItem={translatedApprovalPending}
              />
            )}
          </>
        );
      case I_9_DOCUMENT:
        return (
          <>
            {completedTask}
            {status === CAAS_STATUS.Section1Complete && !isCustomerService(applicationId) && (
              <TaskCardActionItemContent actionItem={actionRequireAtAppointmentSection2} />
            )}
            {(status === CAAS_STATUS.PendingSection3 || status === CAAS_STATUS.Archived) &&
              !isCustomerService(applicationId) && (
                <TaskCardActionItemContent actionItem={actionRequireAtAppointmentSection3} />
              )}
          </>
        );
      default:
        return completedTask;
    }
  }

  if ([StatusEnum.INITIATED].includes(status as StatusEnum) && taskName === BACKGROUNDCHECK_INFORMATION) {
    return <TaskCardActionItemContent taskTitleText={taskTitleText} actionItem={StatusEnum.IN_PROGRESS} />;
  }

  if (
    [NHEAppointmentStatusEnum.UNKNOWN].includes(overrideStatus as NHEAppointmentStatusEnum) &&
    taskName === SCHEDULE_RIGHT_TO_WORK_APPOINTMENT
  ) {
    const resolveDurationTextMessage = resolveDurationText(timeComplete, overrideStatus, duration);
    return (
      <TaskCardActionItemDefaultContent
        taskTitleText={taskTitleText}
        imageOrText={resolveDurationTextMessage}
        actionItem={StatusEnum.ACTION_REQUIRED}
      />
    );
  } else if (
    [NHEAppointmentStatusEnum.RESERVED, NHEAppointmentStatusEnum.COMPLETED].includes(
      overrideStatus as NHEAppointmentStatusEnum
    ) &&
    taskName === SCHEDULE_RIGHT_TO_WORK_APPOINTMENT
  ) {
    return (
      <TaskCardActionItemContent
        taskTitleText={taskTitleText}
        actionItem={overrideStatus === NHEAppointmentStatusEnum.RESERVED ? scheduledMessage : ""}
      />
    );
  }

  if (taskName === I_9_DOCUMENT) {
    if ([StatusEnum.PENDING].includes(status as StatusEnum) && country == SUPPORTED_COUNTRY.GB) {
      return <TaskCardActionItemContent taskTitleText={taskTitleText} actionItem={GB_CAAS_STATUS.InProgress} />;
    }

    if ([StatusEnum.PENDING, StatusEnum.INITIATED].includes(status as StatusEnum) && country == SUPPORTED_COUNTRY.CA) {
      return (
        <TaskCardActionItemDefaultContent
          taskTitleText={taskTitleText}
          imageOrText={resolveDurationTextMessage}
          actionItem={statusText}
        />
      );
    }
  }

  if (taskName === REVIEW_FINAL_OFFER_LETTER) {
    //Todo update with correct status
    return (
      <TaskCardActionItemDefaultContent
        taskTitleText={taskTitleText}
        imageOrText={resolveDurationTextMessage}
        actionItem={statusText}
      />
    );
  }

  if (taskName === PRE_HIRE_ORIENTATION) {
    //Todo update with correct status
    return (
      <TaskCardActionItemDefaultContent
        taskTitleText={taskTitleText}
        imageOrText={resolveDurationTextMessage}
        actionItem={statusText}
      />
    );
  }

  if (status === CAAS_STATUS.Section1Complete && isCustomerService(applicationId)) {
    return (
      <TaskCardActionItemContent
        taskTitleText={taskTitleText}
        actionItem={actionRequireSection1CompleteForCustomerService}
      />
    );
  }

  if ([StatusEnum.SKIPPED].includes(status as StatusEnum) && taskName === ORDER_YOUR_SAFETY_SHOE) {
    return <TaskCardActionItemDefaultContent taskTitleText={taskTitleText} imageOrText={resolveDurationTextMessage} />;
  }

  return (
    <TaskCardActionItemDefaultContent
      taskTitleText={taskTitleText}
      imageOrText={resolveDurationTextMessage}
      actionItem={statusText}
    />
  );
};

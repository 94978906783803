import { ExpanderContent } from "@amzn/stencil-react-components/expander";
import { TriggerButton } from "@amzn/stencil-react-components/helpers";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BACKGROUNDCHECK_INFORMATION,
  BADGE_PHOTO,
  COMPLETE_RIGHT_TO_WORK,
  CREATE_EMPLOYEE_A_TO_Z_ACCOUNT,
  DOC_UPLOADS,
  EMPLOYMENT_PAPERWORK,
  FIRST_DAY_GROUP,
  I_9_DOCUMENT,
  JOB_PREVIEW_VIDEO,
  NEW_HIRE_VIDEO,
  ORDER_YOUR_SAFETY_SHOE,
  PRE_HIRE_APPOINTMENT_GROUP,
  PRE_HIRE_ORIENTATION,
  REVIEW_FINAL_OFFER_LETTER,
  SCHEDULE_RIGHT_TO_WORK_APPOINTMENT,
  TRAINING_DAY_GROUP,
} from "../../config/taskConfigConstants";
import { Task, TaskGroup } from "../../config/tasks";
import { Stage } from "../../helpers/get-settings";
import { withStatus } from "../../hoc/with-status";
import { useChecklistArb } from "../../hooks/use-arb";
import { JobDetailsModel } from "../../utility/application-data";
import { StatusEnum } from "../../utility/enums/common";
import { H2, H3, Text } from "../typography";
import {
  ZapposPurchasePromotion,
  ZapposPurchasePromotionButton,
} from "../zappos-purchase-promotion/zappos-purchase-promotion-flyOut";
import { DueDateIndicator } from "./due-date";
import { ProgressBar, countAsCompletedTask } from "./progress-bar";
import { TaskDetails } from "./task-details";

import { HVH_COLORS } from "../../config/palette";
import { isDragonstone, isSalesforceApplication } from "../../helpers/url-params";
import * as adobeAnalytics from "../../utility/adobe-analytics";
import { EmploymentPaperwork } from "../employment-paperwork/EmploymentPaperwork";
import { I9TaskCard } from "../i9-task/i9-task-card";
import { TaskCardTextContent } from "./TaskCardTextContent";

import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useBreakpoints } from "@amzn/stencil-react-components/responsive";
import moment from "moment";
import {
  RIGHT_TO_WORK_TASK_DEFINITION_ID,
  RIGHT_TO_WORK_TASK_TITLE,
  SUPPORTED_COUNTRY,
} from "../../config/appConfigConstants";
import { ZAPPOS_URL } from "../../config/urls";
import {
  isInfoOnlyTask,
  getI9CaaSResponse,
  getStatusOverride,
  getWorkAuthorizationCardTaskTitle,
} from "../../helpers/utils";
import { useGetApplicationManageData } from "../../hooks/apis/useGetApplicationManageData";
import { useGetCandidateData } from "../../hooks/apis/useGetCandidateData";
import { useGetJobDetails } from "../../hooks/apis/useGetJobDetails";
import { useGetNHEAppointment } from "../../hooks/apis/useGetNHEAppointment";
import { useAshRum } from "../../hooks/useAshRum.hook";
import { useAppDispatch } from "../../reduxStore/reduxHooks";
import { openOldUiFlyoutContentUpload } from "../../reduxStore/slices/docUploadSlice";
import {
  FlyoutAdapterBackBtnForOldDocUpload,
  FlyoutAdapterForOldDocUpload,
} from "../../routes/tasks/document/helpers/FlyoutAdapterForOldDocUpload";
import { getTaskDefinitionsFromConfig, isCustomerService } from "../../utility/app-config-helper";
import { getCountryForDomain } from "../../utility/locale-helper";
import { NHEAppointmentStatusEnum } from "../appointment-card/card-controlled";
import { AtoZAccountCreation } from "../atoz-status/AtoZAccountCreation";
import { CreateEmployeeAccountButton } from "../atoz-status/CreateEmployeeAccountButton";
import { BadgePhoto } from "../badge-photo/badgePhotoInTaskcardFlyoutDetails";
import { BadgePhotoTaskCardButton } from "../badge-photo/badgePhotoTaskCardButton";
import { BGCInfoTaskCardButton } from "../bgc-information/bgcInformationTaskcardButton";
import { BGCInformation } from "../bgc-information/bgcInformationTaskcardFlyoutDetails";
import { EmploymentPaperworkButton } from "../employment-paperwork/EmploymentPaperworkButton";
import { I9TaskCardButton } from "../i9-task/I9TaskCardButton";
import { CAAS_STATUS } from "../i9-task/i9-data";
import { CreateCloseFlyoutButton, NewHireVideoFlyout } from "../new-hire-video/NewHireVideo";
import { RTWScheduleApointmentButton } from "../rtw-appointment-schedule/RtwAppointmentScheduleButton";
import { RTWAppointmentScheduleStatus } from "../rtw-appointment-schedule/index";
import { JobPreviewVideoFlyout } from "../jobPreviewVideo/jobPreviewVideo";
import { P } from "@amzn/stencil-react-components/text";
import { ReviewFinalOfferLetterButton } from "../review-final-offer-letter/ReviewFinalOfferLetterButton";
import { ReviewFinalOfferLetterFlyout } from "../review-final-offer-letter";
import { PreHireOrientationButton } from "../preHireOrientation/preHireOrientationButton";
import { PreHireOrientationFlyout } from "../preHireOrientation";

interface TaskProps {
  task: Task;
  applicationId: string;
  candidateId: string;
  bundle: any;
  onTaskStatusEvent: (k?: string, v?: StatusEnum) => void;
  stage: Stage;
  icon?: string;
  dueDate?: Date;
  shouldOverrideStatus?: Record<string, StatusEnum>;
}
export const TaskItem = ({
  task,
  applicationId,
  candidateId,
  bundle,
  icon,
  onTaskStatusEvent,
  stage,
  dueDate,
  shouldOverrideStatus,
}: TaskProps) => {
  const dispatch = useAppDispatch();
  const scheduleDetails = useGetJobDetails();
  const candidateData = useGetCandidateData();
  const applicationManageData = useGetApplicationManageData();

  const { recordRumEvent } = useAshRum();

  const [businessLine, setBusinessLine] = useState("");
  const [isTaskDetailsViewOpen, setIsTaskDetailsViewOpen] = useState(false);
  const start = bundle.getMessage("Checklist-BPStart-Start");

  const moreInfo = bundle.getMessage("Checklist-Task-Item-More-Information");

  const openTaskDetailsView = useCallback(() => {
    setIsTaskDetailsViewOpen(true);
    sendAdobeEvents();
  }, [isTaskDetailsViewOpen]);

  const closeTaskDetailsView = useCallback(() => {
    setIsTaskDetailsViewOpen(false);

    dispatch(
      openOldUiFlyoutContentUpload({
        oldUiFlyoutContent: { documentType: "FORM", isEdit: false, buttonDelegationEnabled: true },
      })
    );
  }, [isTaskDetailsViewOpen]);

  let enableTask = false;

  // TODO Make the enable/disable task more generic for all tasks and use app config.
  const DisabledBusinessLines = ["AIR"];

  const { requisitionId } = useParams<{
    requisitionId: string;
  }>();

  const sendAdobeEvents = useCallback(() => {
    if (task.getTaskStatusByBBId && task.analyticsOnExpand && candidateData) {
      adobeAnalytics.addEventMetricWithBBId(candidateData, window, applicationId, "click text", {
        text: task.analyticsOnExpand,
        jobId: requisitionId,
        isDragonstone: isDragonstone(requisitionId),
      });
    } else {
      adobeAnalytics.addEventMetric(
        window,
        applicationId,
        "click text",
        candidateData,
        applicationManageData,
        scheduleDetails,
        {
          text: task.analyticsOnExpand,
          jobId: requisitionId,
          isDragonstone: isDragonstone(requisitionId),
        }
      );
    }
  }, [task, applicationId, candidateId, requisitionId, candidateData, applicationManageData, scheduleDetails]);

  const {
    matches: { s: isSmallViewport },
  } = useBreakpoints();

  useEffect(() => {
    if (!isSalesforceApplication(applicationId) && scheduleDetails) {
      setBusinessLine(scheduleDetails?.businessLine as string);
    }
  }, [scheduleDetails]);

  const renderCard = (status?: StatusEnum, taskId?: string) => {
    // status passed in is the real status from api, override the status if needed
    const overrideStatus = getStatusOverride(shouldOverrideStatus, status);
    const country = getCountryForDomain();

    const onClickAction = () => {
      const NOT_VALID_STATES_FOR_OPENING_TASK_FLYOUT: StatusEnum[] = [
        StatusEnum.NOT_AVAILABLE_YET,
        StatusEnum.INELIGIBLE,
        StatusEnum.REJECTED,
      ];
      const isEligibleToOpenFlyout = !NOT_VALID_STATES_FOR_OPENING_TASK_FLYOUT.includes(overrideStatus as StatusEnum);

      if (task.taskName === DOC_UPLOADS && country === SUPPORTED_COUNTRY.MX) {
        if (isEligibleToOpenFlyout) openTaskDetailsView();
        return;
      }

      if (isEligibleToOpenFlyout) openTaskDetailsView();
    };

    //Todo: remove this when CaaS integration is done for RTW
    const getButtonText = () =>
      isInfoOnlyTask({ isCustomerService: isCustomerService(applicationId), country, task }) ? moreInfo : start;

    return (
      <li id="activities">
        <Row display="block">
          <Col key={task.titleText} minWidth={window.length} onClick={onClickAction}>
            <button className={"task-item-button"} data-testid={`task-${task.taskName}`}>
              <Row>
                {task.taskName === ORDER_YOUR_SAFETY_SHOE ? (
                  <Col width={"6px"} />
                ) : (
                  <Col backgroundColor={renderBGColor(overrideStatus)} width={"6px"} />
                )}
                <Col>
                  <Row>
                    <DueDateIndicator
                      testIdText={`due-date-indicator-${task.taskName}`}
                      status={overrideStatus}
                      dueDate={dueDate}
                      task={task}
                      applicationId={applicationId}
                    />
                  </Row>
                  <Row>
                    <Col padding="S300">
                      <img src={icon} alt="" width="72px" />
                    </Col>
                    <Col padding="S300">
                      <TaskCardTextContent
                        taskTitleText={task.titleText}
                        taskName={task.taskName}
                        status={status}
                        shouldOverrideStatus={shouldOverrideStatus}
                        duration={task.duration}
                        applicationId={applicationId}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col flex={1} />
                <Col alignSelf={"end"} padding="S300">
                  {!isSmallViewport && showStartButton(overrideStatus, status, task) && (
                    <Button
                      data-testid={`start-button-${task.taskName}`}
                      as="div"
                      size={ButtonSize.Small}
                      type={null}
                      variant={ButtonVariant.Primary}
                    >
                      <div aria-hidden={true}>{getButtonText()}</div>
                    </Button>
                  )}
                </Col>
              </Row>
            </button>
          </Col>
        </Row>
        <TaskDetails
          title={getFlyoutTitle(task.taskName, applicationId, bundle)}
          detailsTitle={getDetailsTitle(bundle, task.taskName, applicationId, country)}
          taskName={task.taskName}
          buttons={getTaskDetailsButtons(
            task.titleStatusPath,
            taskId,
            task.taskName,
            status,
            shouldOverrideStatus,
            applicationId,
            closeTaskDetailsView
          )}
          flyoutContent={getTaskDetailsFlyoutContent(
            task.taskName,
            task.titleStatusPath,
            taskId,
            status,
            shouldOverrideStatus,
            applicationId
          )}
          isTaskDetailsViewOpen={isTaskDetailsViewOpen}
          closeTaskDetailsView={closeTaskDetailsView}
          duration={task.duration}
          status={status}
        />
      </li>
    );
  };

  if (!task?.hideOnConfig) {
    if (task.checkBusinessLine && DisabledBusinessLines.includes(businessLine)) {
      enableTask = false;
    } else {
      enableTask = true;
    }
  }

  return withStatus({
    key: task.titleText,
    taskName: task.taskName,
    statusPath: task.titleStatusPath,
    taskStatusName: task.taskStatusName,
    getTaskStatusByBBId: task.getTaskStatusByBBId,
    applicationId,
    candidateId,
    hideWhenError: task.hideWhenError,
    renderComponent: renderCard,
    shouldOverrideStatus: task.shouldOverrideStatus,
    enableTask: enableTask,
    bundle,
    onTaskStatusEvent,
    businessLine,
    defaultStatus: task.defaultStatus,
    recordEvent: recordRumEvent,
  });
};

interface TasksGroupProps {
  data: TaskGroup;
  applicationId: string;
  candidateId: string;
  onTaskStatusEvent: (k?: string, v?: StatusEnum) => void;
  taskStatusMap: Map<string, StatusEnum>;
  stage: Stage;
  bundle: any;
  nheDetails?: AppointmentInfo;
  jcDetails?: JobDetailsModel;
}
export const TasksGroup = ({
  data,
  applicationId,
  candidateId,
  onTaskStatusEvent,
  taskStatusMap,
  stage,
  bundle,
  nheDetails,
  jcDetails,
}: TasksGroupProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const allTasksCompleted = useRef(false); // persist data between rerender, to automatically hide the tasks when all tasks completed.

  let dueDate: Date | undefined;
  let completedTasks = 0;
  let totalTasks = 0;
  const validStatusArray = [StatusEnum.COMPLETED, StatusEnum.NO_ACTION_REQUIRED];
  data.tasks.forEach((task: Task) => {
    const statusTitle = taskStatusMap.get(task.titleText);
    if (statusTitle == StatusEnum.SKIPPED) return;
    if (taskStatusMap.has(task.titleText)) {
      if (countAsCompletedTask(statusTitle, validStatusArray)) {
        completedTasks++;
      }
      totalTasks++;
    }
  });

  if (data.id === PRE_HIRE_APPOINTMENT_GROUP && nheDetails?.startTimestamp) {
    // Old behavior was to send empty string for nheDate when nhe is today, this drives 'overdue' status
    const dueMoment = moment(nheDetails.startTimestamp);
    // Use local timezone for comparing dates to avoid user confusion
    dueDate = moment().isSame(dueMoment, "day") ? undefined : dueMoment.toDate();
  } else if (data.id === FIRST_DAY_GROUP && jcDetails?.firstDayOnSite) {
    dueDate = moment(jcDetails.firstDayOnSite, "YYYY-MM-DD").toDate();
  } else if (data.id === TRAINING_DAY_GROUP && jcDetails?.firstDayOnSite) {
    //Todo: update based on traninig day field
    dueDate = moment(jcDetails.firstDayOnSite, "YYYY-MM-DD").toDate();
  }

  const isAllTasksCompleted =
    allTasksCompleted.current === false && totalTasks === data.tasks.length && completedTasks === totalTasks;

  if (isAllTasksCompleted) {
    allTasksCompleted.current = true;
  }

  // Will hide the tasks initially when all tasks is completed.
  useEffect(() => {
    if (allTasksCompleted.current) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, [allTasksCompleted.current]);

  return (
    <Col data-testid={`task-group-sub-header-${data.id}-${completedTasks}-${totalTasks}`}>
      <Col backgroundColor={HVH_COLORS.NEUTRAL_0}>
        <Row alignItems="center" gridGap="S200" padding={"1rem"} onClick={() => setIsExpanded(!isExpanded)}>
          <TriggerButton
            iconAltText={data.name}
            isExpanded={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            data-testid={`task-group-sub-header-button-${data.name}`}
          />
          <H3 color={"primary70"}> {data.name} </H3>
          <Text color={"neutral90"}>
            {completedTasks}/{totalTasks}
          </Text>
        </Row>
        <ExpanderContent isExpanded={isExpanded} data-testid={`task-group-list-${data.id}`}>
          <ul className="task-group-list">
            {data.tasks.map((task: Task, index: number) => (
              <TaskItem
                key={task.titleText + index}
                task={task}
                applicationId={applicationId}
                candidateId={candidateId}
                bundle={bundle}
                icon={task.icon}
                onTaskStatusEvent={onTaskStatusEvent}
                stage={stage}
                dueDate={dueDate}
                shouldOverrideStatus={task.shouldOverrideStatus}
              />
            ))}
          </ul>
        </ExpanderContent>
      </Col>
    </Col>
  );
};

interface TaskGroupAggregatorProps {
  groups: TaskGroup[];
  candidateId: string;
  stage: Stage;
}
export const TaskGroupAggregator = ({ groups, candidateId, stage }: TaskGroupAggregatorProps) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const scheduleDetails = useGetJobDetails();

  // We will always have NHE for all of the countries. We should refer to NHE for getting due date indicator
  const nheInfo = useGetNHEAppointment();
  const nheDetails = nheInfo?.errorStatusCode ? undefined : (nheInfo as AppointmentInfo);

  const bundle = useChecklistArb();
  const activitiesText = bundle.getMessage("Checklist-MainPage-SubTitle4");
  const refreshNote = bundle.getMessage("Checklist-MainPage-RefreshToReload");
  const [taskStatusMap, setTaskStateMap] = useState(new Map<string, StatusEnum>());
  const [jcDetails, setJcDetails] = useState<JobDetailsModel | undefined>();

  useEffect(() => {
    if (scheduleDetails) {
      const jcDetails = scheduleDetails;
      if (jcDetails?.error?.errorCode !== "APPLICATION_DETAILS_NOT_FOUND") {
        setJcDetails(jcDetails);
      }
    }
  }, [scheduleDetails]);

  const taskStatusHandler = (k?: string, v?: StatusEnum) => {
    if (k && v) {
      setTaskStateMap((prevState) => {
        return new Map(prevState).set(k, v);
      });
    }
  };

  const result = (
    <Col backgroundColor={HVH_COLORS.NEUTRAL_10}>
      <Row padding="1rem" justifyContent={"space-between"}>
        <H2>{activitiesText}</H2>
        <ProgressBar taskStatusMap={taskStatusMap} bundle={bundle} />
      </Row>
      <Row padding="1rem">
        <P>{refreshNote}</P>
      </Row>
      {groups.map((taskGroup: TaskGroup, index: number) => (
        <TasksGroup
          key={taskGroup.name + index}
          data={taskGroup}
          applicationId={applicationId}
          candidateId={candidateId}
          onTaskStatusEvent={taskStatusHandler}
          taskStatusMap={taskStatusMap}
          stage={stage}
          nheDetails={nheDetails}
          jcDetails={jcDetails}
          bundle={bundle}
        />
      ))}
    </Col>
  );

  return result;
};

export const renderBGColor = (
  status?: StatusEnum | CAAS_STATUS | NHEAppointmentStatusEnum,
  shouldOverrideStatus?: Record<string, StatusEnum>
) => {
  const overrideStatus = getStatusOverride(shouldOverrideStatus, status);

  switch (overrideStatus) {
    case NHEAppointmentStatusEnum.COMPLETED:
    case StatusEnum.NO_ACTION_REQUIRED:
    case StatusEnum.COMPLETED:
      return HVH_COLORS.GREEN_60;
    case StatusEnum.API_ERROR:
      return HVH_COLORS.NEUTRAL_100;
    case StatusEnum.ERROR:
      return HVH_COLORS.RED_70;
    case StatusEnum.INELIGIBLE:
    case StatusEnum.NOT_AVAILABLE_YET:
      return HVH_COLORS.NEUTRAL_70;
    case StatusEnum.REJECTED:
      // can't show Rejected on the screen for legal reason. Instead, we should show Completed in the flyout with green banner
      // https://issues.amazon.com/issues/Kondo_QA_Issue-653
      return HVH_COLORS.GREEN_60;
    default:
      return HVH_COLORS.YELLOW_60;
  }
};

export function getTaskDetailsButtons(
  caasPath?: string,
  taskId?: string,
  taskName?: string,
  status?: StatusEnum,
  shouldOverrideStatus?: Record<string, StatusEnum>,
  applicationId?: string,
  closeTaskDetailsView?: any
): React.ReactNode {
  if (taskName === ORDER_YOUR_SAFETY_SHOE) {
    if (!taskId || status == StatusEnum.COMPLETED) {
      return null;
    }

    return <ZapposPurchasePromotionButton href={ZAPPOS_URL} taskId={taskId} />;
  } else if (taskName === I_9_DOCUMENT) {
    if (!status) {
      return null;
    }

    const country = getCountryForDomain();

    if (country === SUPPORTED_COUNTRY.MX) {
      return <FlyoutAdapterBackBtnForOldDocUpload />;
    }

    const taskDefinition = getTaskDefinitionsFromConfig(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId);
    if (!taskDefinition || !taskDefinition.isIntegratedWithCaaS) {
      return null;
    }

    return <I9TaskCardButton status={status} shouldOverrideStatus={shouldOverrideStatus} />;
  } else if (taskName === BADGE_PHOTO) {
    if (!status) {
      return null;
    }
    return <BadgePhotoTaskCardButton status={status} shouldOverrideStatus={shouldOverrideStatus} />;
  } else if (taskName === BACKGROUNDCHECK_INFORMATION) {
    if (!status) {
      return null;
    }
    return <BGCInfoTaskCardButton status={status} country={getCountryForDomain()} />;
  } else if (taskName === SCHEDULE_RIGHT_TO_WORK_APPOINTMENT) {
    if (!status) {
      return null;
    }
    return <RTWScheduleApointmentButton status={status} shouldOverrideStatus={shouldOverrideStatus} />;
  } else if (taskName === EMPLOYMENT_PAPERWORK) {
    if (!status || status == StatusEnum.COMPLETED) {
      return null;
    }
    return <EmploymentPaperworkButton status={status} />;
  } else if (taskName === REVIEW_FINAL_OFFER_LETTER) {
    if (!status) {
      return null;
    }
    return <ReviewFinalOfferLetterButton status={status} shouldOverrideStatus={shouldOverrideStatus} />;
  } else if (taskName === PRE_HIRE_ORIENTATION) {
    if (!status) {
      return null;
    }
    return <PreHireOrientationButton status={status} shouldOverrideStatus={shouldOverrideStatus} />;
  } else if (taskName === CREATE_EMPLOYEE_A_TO_Z_ACCOUNT) {
    if (!status) {
      return null;
    }
    return <CreateEmployeeAccountButton status={status} />;
  } else if (taskName === NEW_HIRE_VIDEO || taskName === JOB_PREVIEW_VIDEO) {
    // This is needed because if the <video> tag is the last focusable tag of the flyout we can't tab to the video controls.
    // Stencil team is aware of it and might fix it, but we do not have time to wait.
    return <CreateCloseFlyoutButton onClick={closeTaskDetailsView} />;
  } else if (taskName === DOC_UPLOADS) {
    return <FlyoutAdapterBackBtnForOldDocUpload />;
  }
}

export function updateStatusForAshLite(
  isIntegratedWithCaaS: boolean,
  applicationId?: string,
  status?: StatusEnum,
  taskDefinition?: any
) {
  if (
    taskDefinition &&
    taskDefinition.taskTitle == RIGHT_TO_WORK_TASK_TITLE &&
    applicationId &&
    isCustomerService(applicationId)
  ) {
    return CAAS_STATUS.UkRtwNoCaasIntegration;
  }

  if (
    taskDefinition &&
    taskDefinition.taskTitle == RIGHT_TO_WORK_TASK_TITLE &&
    applicationId &&
    !isCustomerService(applicationId) &&
    !isIntegratedWithCaaS
  ) {
    return CAAS_STATUS.UkRtwNoCaasIntegration;
  }

  return status;
}

export function getTaskDetailsFlyoutContent(
  taskName: string | undefined,
  caasPath?: string,
  taskId?: string,
  status?: StatusEnum,
  shouldOverrideStatus?: Record<string, StatusEnum>,
  applicationId?: string
): React.ReactNode {
  const country = getCountryForDomain();

  if (taskName == undefined || !applicationId) return null;
  switch (taskName) {
    case ORDER_YOUR_SAFETY_SHOE: {
      if (status && country === SUPPORTED_COUNTRY.CA) {
        return <ZapposPurchasePromotion status={status} />;
      }

      if (!taskId || !status) {
        return null;
      }

      return <ZapposPurchasePromotion status={status} taskId={taskId} />;
    }
    case DOC_UPLOADS: {
      return <FlyoutAdapterForOldDocUpload status={status || StatusEnum.ACTION_REQUIRED} taskName={DOC_UPLOADS} />;
    }
    case I_9_DOCUMENT: {
      const country = getCountryForDomain();

      if (country === SUPPORTED_COUNTRY.MX && !isCustomerService(applicationId)) {
        return <FlyoutAdapterForOldDocUpload status={status || StatusEnum.ACTION_REQUIRED} taskName={I_9_DOCUMENT} />;
      }
      const { isIntegratedWithCaaS, status: updatedStatus } = getI9CaaSResponse(applicationId, status);

      if (!status && isIntegratedWithCaaS) {
        return null;
      }

      return <I9TaskCard status={updatedStatus} shouldOverrideStatus={shouldOverrideStatus} />;
    }

    case BADGE_PHOTO: {
      if (!status) {
        return null;
      }
      return <BadgePhoto status={status} shouldOverrideStatus={shouldOverrideStatus} />;
    }
    case BACKGROUNDCHECK_INFORMATION: {
      if (!status) {
        return null;
      }
      return <BGCInformation status={status} country={getCountryForDomain()} />;
    }
    case SCHEDULE_RIGHT_TO_WORK_APPOINTMENT: {
      if (!status) {
        return null;
      }
      return <RTWAppointmentScheduleStatus status={status} shouldOverrideStatus={shouldOverrideStatus} />;
    }
    case REVIEW_FINAL_OFFER_LETTER: {
      if (!status) {
        return null;
      }
      return <ReviewFinalOfferLetterFlyout status={status} shouldOverrideStatus={shouldOverrideStatus} />;
    }
    case PRE_HIRE_ORIENTATION: {
      if (!status) {
        return null;
      }
      return <PreHireOrientationFlyout status={status} shouldOverrideStatus={shouldOverrideStatus} />;
    }
    case NEW_HIRE_VIDEO: {
      return <NewHireVideoFlyout isTask={true} />;
    }
    case JOB_PREVIEW_VIDEO: {
      return <JobPreviewVideoFlyout />;
    }
    case EMPLOYMENT_PAPERWORK: {
      if (!status) {
        return null;
      }
      return <EmploymentPaperwork status={status} />;
    }
    case CREATE_EMPLOYEE_A_TO_Z_ACCOUNT: {
      if (!status) {
        return null;
      }
      return <AtoZAccountCreation status={status} />;
    }
    default: {
      return <Text>Unknown task</Text>;
    }
  }
}

export function showStartButton(
  overrideStatus: StatusEnum | NHEAppointmentStatusEnum | undefined,
  status?: StatusEnum | undefined,
  task?: Task
) {
  const country = getCountryForDomain();

  if (task?.titleText == BACKGROUNDCHECK_INFORMATION) {
    return overrideStatus === StatusEnum.AWAITING_CANDIDATE_INFO;
  }

  if (task?.titleText == COMPLETE_RIGHT_TO_WORK) {
    return status === StatusEnum.AWAITING_CANDIDATE_INFO || overrideStatus == StatusEnum.ACTION_REQUIRED;
  }

  if (task?.titleText == SCHEDULE_RIGHT_TO_WORK_APPOINTMENT) {
    return overrideStatus === NHEAppointmentStatusEnum.UNKNOWN;
  }

  if (task?.taskName == ORDER_YOUR_SAFETY_SHOE && country === SUPPORTED_COUNTRY.CA) {
    return status === StatusEnum.SKIPPED;
  }

  return !(
    overrideStatus === StatusEnum.COMPLETED ||
    overrideStatus === StatusEnum.NO_ACTION_REQUIRED ||
    overrideStatus === StatusEnum.NOT_AVAILABLE_YET ||
    overrideStatus === StatusEnum.INELIGIBLE ||
    overrideStatus === StatusEnum.PENDING_REVIEW ||
    overrideStatus === StatusEnum.ERROR ||
    overrideStatus === StatusEnum.PENDING ||
    overrideStatus === StatusEnum.REJECTED
  );
}

export function getFlyoutTitle(taskName: string | undefined, applicationId: string | undefined, bundle: any) {
  switch (taskName) {
    case ORDER_YOUR_SAFETY_SHOE:
      return bundle.getMessage("Checklist-Flyout-Shoe-Details");
    case I_9_DOCUMENT:
      return getTaskDefinitionsFromConfig(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId)?.taskTitle !==
        RIGHT_TO_WORK_TASK_TITLE && getCountryForDomain() == SUPPORTED_COUNTRY.US
        ? bundle.getMessage("Checklist-Flyout-I9-Details")
        : bundle.getMessage("Checklist-Flyout-RightToWork-Details");
    case BADGE_PHOTO:
      return bundle.getMessage("Checklist-Flyout-Badge-Details");
    case EMPLOYMENT_PAPERWORK:
      return bundle.getMessage("Checklist-Flyout-Paperwork-Details");
    case CREATE_EMPLOYEE_A_TO_Z_ACCOUNT:
      return bundle.getMessage("Checklist-Flyout-EmployeeAccount-Details");
    case NEW_HIRE_VIDEO:
      return bundle.getMessage("Checklist-Flyout-Video-Details");
    case SCHEDULE_RIGHT_TO_WORK_APPOINTMENT:
      return bundle.getMessage("Checklist-Flyout-RTW-Appointment-Details-Title");
    case REVIEW_FINAL_OFFER_LETTER:
      return bundle.getMessage("Checklist-MainPage-Review-Final-Offer-Letter-Title");
    case PRE_HIRE_ORIENTATION:
      return bundle.getMessage("Checklist-MainPage-Pre-Hire-Orientation-Title");
    case JOB_PREVIEW_VIDEO:
      return bundle.getMessage("Checklist-MainPage-Job-Preview-Video-Title");
    default:
      return null;
  }
}

export function getDetailsTitle(
  bundle: any,
  taskName: string | undefined,
  applicationId?: string,
  country?: string
): string {
  switch (taskName) {
    case ORDER_YOUR_SAFETY_SHOE:
      return country === SUPPORTED_COUNTRY.CA
        ? bundle.getMessage("Checklist-MainPage-MindYourStep-Title-CA")
        : bundle.getMessage("Checklist-MainPage-MindYourStep-Title");
    case I_9_DOCUMENT:
      return bundle.getMessage(
        getWorkAuthorizationCardTaskTitle(
          getTaskDefinitionsFromConfig(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId)?.taskTitle,
          getCountryForDomain()
        )
      );
    case DOC_UPLOADS:
      return bundle.getMessage("Checklist-docUpload-header");
    case BADGE_PHOTO:
      return bundle.getMessage("Checklist-MainPage-BadgePhoto-Title");
    case EMPLOYMENT_PAPERWORK:
      return bundle.getMessage("Checklist-Complete-Employment-Paperwork");
    case CREATE_EMPLOYEE_A_TO_Z_ACCOUNT:
      return bundle.getMessage("Checklist-AtoZ-Setup-Button");
    case NEW_HIRE_VIDEO:
      return bundle.getMessage("Checklist-MainPage-Video-Title");
    case SCHEDULE_RIGHT_TO_WORK_APPOINTMENT:
      return bundle.getMessage("Checklist-Flyout-RTW-Appointment-Details-Message");
    case REVIEW_FINAL_OFFER_LETTER:
      return bundle.getMessage("Checklist-MainPage-Review-Final-Offer-Letter-Title");
    case PRE_HIRE_ORIENTATION:
      return bundle.getMessage("Checklist-MainPage-Pre-Hire-Orientation-Title");
    case JOB_PREVIEW_VIDEO:
      return bundle.getMessage("Checklist-MainPage-Job-Preview-Video-Title");
    default:
      return taskName ?? "";
  }
}

/* eslint-disable import/no-unused-modules */
import {
  CAAS_STATUS,
  mockAllContingenciesResponse,
  mockAppConfigResponse,
  mockApplicationManageDataResponse,
  mockAppointmentInfo,
  mockAtoZResponse,
  mockCDSResponse,
  mockJobDetailsResponse,
  mockRtwStatusResponse,
  StatusEnum,
} from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { DefaultBodyType, MockedRequest, RestHandler, rest } from "msw";
import { APPLICATION_STATE } from "../utility/constants/application-status";

/*
  These mocks are useful for overriding the default value of the real API requests.
  Change the payload to expected value, and include that later on "tempMocks".

  Make sure we are using the same value for "test" environment and unit test for mocks to works.
*/

const MOCK_ENV_API_URL_PREFIX =
  process.env.NODE_ENV === "test" ? "http://localhost/checklist/api" : "http://localhost:9000/checklist/api";
const MOCK_LOCALE = "en-US";
const MOCK_TASK_TYPE =
  "JOB_PREVIEW_VIDEO%23https%3A%2F%2Fd3jau441twxx89.cloudfront.net%2FnewHireVideo_CustomerService_es-MX.mp4";
const MOCK_ENV_APPLICATION_ID = process.env.NODE_ENV === "test" ? "app-id" : "4f4ce9ea-919f-490f-8168-2fa3ca2530f7";
const MOCK_ENV_SF_SERVICE_FAILURE_APPLICATION_ID = "SF_SERVICE_FAILURE_APP_ID";
const MOCK_ENV_SF_PARSE_FAILURE_APPLICATION_ID = "SF_PARSE_FAILURE_APP_ID";
const MOCK_ENV_REQUISITION_ID = process.env.NODE_ENV === "test" ? "req-id" : "";
const MOCK_ENV_CANDIDATE_ID = process.env.NODE_ENV === "test" ? "candidate-id" : "55db7795-1238-4ee1-8403-ce696651220c";
const MOCK_ENV_CANDIDATE_GLOBAL_ID = process.env.NODE_ENV === "test" ? "candidateGlobalId" : "CIDNA8900015728";
const MOCK_ENV_CANDIDATE_SF_CANDIDATE_ID = process.env.NODE_ENV === "test" ? "sfCandidateId" : "CID078133637";
const MOCK_ENV_REDIRECT_URL =
  process.env.NODE_ENV === "test"
    ? "http%3A%2F%2Flocalhost%2F"
    : `http%3A%2F%2Flocalhost%3A3000%2Fchecklist%2FJOB-US-0000091094%2F${MOCK_ENV_APPLICATION_ID}`;

const mockGetNewHireVideo = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/video/${MOCK_ENV_APPLICATION_ID}/en-US/${MOCK_ENV_REQUISITION_ID}`,
  (req, res, ctx) => {
    return res(ctx.json({ uri: "https://d1aauhfpu9ujsl.cloudfront.net/newHireVideo1_en_US.mp4" }), ctx.delay(150));
  }
);

const mockGetAppConfig = rest.get(`${MOCK_ENV_API_URL_PREFIX}/getAppConfig/getASHConfig`, (req, res, ctx) => {
  return res(ctx.json(mockAppConfigResponse()), ctx.delay(150));
});

const mockGetCsrfToken = rest.get(`${MOCK_ENV_API_URL_PREFIX}/csrf`, (req, res, ctx) => {
  return res(ctx.json({ "anti-csrftoken-a2z": "fBBYGRpR-g_hTkcHtUw4gZkpRcK4DknTfH6o" }), ctx.delay(150));
});

const mockPutCsrfToken = rest.post(`${MOCK_ENV_API_URL_PREFIX}/test/csrf`, (req, res, ctx) => {
  return res(ctx.json("success post csrf"), ctx.delay(150));
});

const mockGetCandidateData = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/cds/candidate/${MOCK_ENV_APPLICATION_ID}`,
  (req, res, ctx) => {
    return res(ctx.json({ ...mockCDSResponse(), candidateId: "app-id" }), ctx.delay(150));
  }
);

const mockDTWithBusinessLine = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/status/drug-test/candidateGlobalId/${MOCK_ENV_CANDIDATE_GLOBAL_ID}/businessLine/AGL`,
  (req, res, ctx) => {
    return res(
      ctx.json({
        errorMessage: "Failed to get drug test status for candidateGlobalId CIDNA8900015728.",
      }),
      ctx.status(500)
    );
  }
);

const mockGetScheduleDetailsNonSF = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/jobcontainer/schedule/${MOCK_ENV_APPLICATION_ID}/en-US`,
  (req, res, ctx) => {
    return res(
      ctx.json({ ...mockJobDetailsResponse(), scheduleId: "SCH-US-0000305011-non-sf", businessLine: "AIR" }),
      ctx.delay(150)
    );
  }
);

const mockCaasRTWStatus = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/status/right-to-work/candidate/${MOCK_ENV_CANDIDATE_ID}`,
  (req, res, ctx) => {
    return res(ctx.json(mockRtwStatusResponse()), ctx.delay(150));
  }
);

const mockAllContigencies = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/status/allContingencies/${MOCK_ENV_APPLICATION_ID}/${MOCK_ENV_CANDIDATE_ID}/${MOCK_ENV_REDIRECT_URL}/${MOCK_LOCALE}`,
  (req, res, ctx) => {
    return res(ctx.json(mockAllContingenciesResponse()), ctx.delay(150));
  }
);

const mockAllContigenciesStatus = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/status/allContingencies/${MOCK_ENV_SF_PARSE_FAILURE_APPLICATION_ID}/${MOCK_ENV_CANDIDATE_ID}/${MOCK_ENV_REDIRECT_URL}`,
  (req, res, ctx) => {
    return res(
      ctx.json({
        RightToWork: "RightToWork",
        BGC: "BGC",
        WorkAuthorization: "WorkAuthorization",
      }),
      ctx.delay(150)
    );
  }
);

const mockFallbackCall = rest.get(`${MOCK_ENV_API_URL_PREFIX}/fallback`, (req, res, ctx) => {
  return res(ctx.json({ fallback: "fallback" }), ctx.delay(150));
});

const mockGetScheduleDetailsSF = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/salesforce/jobdetails/${MOCK_ENV_APPLICATION_ID}/en-US`,
  (req, res, ctx) => {
    return res(
      ctx.json({ ...mockJobDetailsResponse(), scheduleId: "SCH-US-0000305011-sf", businessLine: "AGL" }),
      ctx.delay(150)
    );
  }
);

const mockGetScheduleDetailsForSFServiceFailure = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/salesforce/jobdetails/${MOCK_ENV_SF_SERVICE_FAILURE_APPLICATION_ID}/en-US`,
  (req, res, ctx) => {
    return res(
      ctx.json({ error: { errorMessage: "Error message", errorCode: "SF_SERVICE_FAILURE", errorStatusCode: 500 } }),
      ctx.delay(150)
    );
  }
);

const mockGetScheduleDetailsForSFParseFailure = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/salesforce/jobdetails/${MOCK_ENV_SF_PARSE_FAILURE_APPLICATION_ID}/en-US`,
  (_req, res, ctx) => {
    return res(
      ctx.body(`
<!doctype html>
<html lang="en">
</html>
`),
      ctx.delay(150)
    );
  }
);

const mockPutCaasI9 = rest.post(
  `${MOCK_ENV_API_URL_PREFIX}/caas/putcaas/${MOCK_ENV_CANDIDATE_ID}/${MOCK_ENV_REDIRECT_URL}`,
  (req, res, ctx) => {
    return res(
      ctx.json({
        status: CAAS_STATUS.Completed,
        secureURL: "https://www.google.com/search?q=dog",
        isRemote: false,
      }),
      ctx.delay(150)
    );
  }
);

const mockGetCaasI9 = rest.get(`${MOCK_ENV_API_URL_PREFIX}/caas/${MOCK_ENV_CANDIDATE_ID}`, (req, res, ctx) => {
  return res(
    ctx.json({
      status: CAAS_STATUS.Completed,
      secureURL: "https://www.google.com/search?q=cat",
      isRemote: false,
    }),
    ctx.delay(150)
  );
});

const mockNHEAppointmentV2 = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/nhe/appointment-v2/${MOCK_ENV_APPLICATION_ID}/NEW_HIRE_EVENT/en-US`,
  (req, res, ctx) => {
    return res(
      ctx.json({
        ...mockAppointmentInfo(),
        timezone: "UTC",
        startTimestamp: "2022-02-12T14:34:00.000Z",
        endTimestamp: "2022-02-12T15:14:00.000Z",
        locationType: "VIRTUAL_CONNECT",
        status: "COMPLETED",
        physicalAddress: {
          addressLine1: "2265 Oak Street",
          addressLine2: "",
          city: "Old Forge",
          state: "ID",
          country: "US",
          zipCode: "13420",
        },
        displayReadyLocation: "2265 Oak Street, Old Forge, ID 13420",
        supportedLanguage: ["en-US", "es-US"],
        appointmentPublicText: "Appointment type details",
        locationPublicText: "The door is on the southwest corner of the building",
      }),
      ctx.delay(150)
    );
  }
);

const mockJobPreviewVideoDetails = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/taskstatus/${MOCK_ENV_APPLICATION_ID}/taskType/${MOCK_TASK_TYPE}`,
  (req, res, ctx) => {
    return res(
      ctx.json({
        status: "Completed",
      }),
      ctx.delay(150)
    );
  }
);

const mockDTAppointment = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/nhe/appointment-v2/${MOCK_ENV_APPLICATION_ID}/DRUG_TEST/en-US`,
  (req, res, ctx) => {
    return res(
      ctx.json({
        ...mockAppointmentInfo(),
        timezone: "UTC",
        startTimestamp: "2024-08-12T14:34:00.000Z",
        endTimestamp: "2024-08-12T15:14:00.000Z",
        locationType: "PHYSICAL",
        status: "RESERVED",
        physicalAddress: {
          addressLine1: "2265 Oak Street",
          addressLine2: "",
          city: "Old Forge",
          state: "ID",
          country: "US",
          zipCode: "13420",
        },
        displayReadyLocation: "2265 Oak Street, Old Forge, ID 13420",
        supportedLanguage: ["en-US", "es-US"],
        appointmentPublicText: "Appointment type details",
        locationPublicText: "The door is on the southwest corner of the building",
      }),
      ctx.delay(150)
    );
  }
);

const mockRTWAppointmentV2 = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/nhe/appointment-v2/${MOCK_ENV_APPLICATION_ID}/RIGHT_TO_WORK/en-US`,
  (req, res, ctx) => {
    return res(ctx.json(mockAppointmentInfo()), ctx.delay(150));
  }
);

const mockGetApplicationManageDataNonSF = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/application/application-manage-data/${MOCK_ENV_APPLICATION_ID}/en-US`,
  (req, res, ctx) => {
    return res(
      ctx.json({
        ...mockApplicationManageDataResponse(),
        enableCSS: true,
        applicationState: "APPT_1_PENDING",
      }),
      ctx.delay(150)
    );
  }
);

const mockGetApplicationManageDataSF = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/salesforce/application-manage-data/${MOCK_ENV_APPLICATION_ID}`,
  (req, res, ctx) => {
    return res(ctx.json(mockApplicationManageDataResponse()), ctx.delay(150));
  }
);

const mockGetApplicationManageDataForSFServiceFailure = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/salesforce/application-manage-data/${MOCK_ENV_SF_SERVICE_FAILURE_APPLICATION_ID}`,
  (req, res, ctx) => {
    return res(
      ctx.json({ error: { errorMessage: "Error message", errorCode: "SF_SERVICE_FAILURE", errorStatusCode: 500 } }),
      ctx.delay(150)
    );
  }
);

const mockGetCandidateDataAtoZWithoutPassword = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/atoz/${MOCK_ENV_CANDIDATE_GLOBAL_ID}/${MOCK_ENV_APPLICATION_ID}/en-US/false`,
  (req, res, ctx) => {
    return res(ctx.json({ ...mockAtoZResponse(), aToZLoginUrl: undefined, isAccountReady: true }), ctx.delay(150));
  }
);

const mockGetCandidateDataAtoZWithPassword = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/atoz/${MOCK_ENV_CANDIDATE_GLOBAL_ID}/${MOCK_ENV_APPLICATION_ID}/en-US/true`,
  (req, res, ctx) => {
    return res(ctx.json(mockAtoZResponse()), ctx.delay(150));
  }
);

const mockGetTaskDocumentStatus = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/status/document-status/${MOCK_ENV_CANDIDATE_GLOBAL_ID}/${MOCK_ENV_APPLICATION_ID}`,
  (req, res, ctx) => {
    return res(ctx.json({ status: StatusEnum.COMPLETED }), ctx.delay(150));
  }
);

const mockGetOnbaseDocumentStatus = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/document-status/${MOCK_ENV_CANDIDATE_GLOBAL_ID}/${MOCK_ENV_APPLICATION_ID}/Salesforce/False/${MOCK_LOCALE}`,
  (req, res, ctx) => {
    const edmLoginEnabled = req.url.searchParams.get("edmLoginEnabled");
    return res(
      ctx.json({
        results: [
          {
            error: null,
            candidateId: "CIDNA8900015728",
            documentsAvailable: true,
            documentCompletionStatus: "Pending",
            documents: null,
            onbaseLoginUrl:
              "https://welcome.gamma.docs.amazon.com/?login_hint=amazonhvh01%2B12122023%40gmail.com%3AUSA",
          },
        ],
        errorCode: edmLoginEnabled === "true" ? "" : "500",
        errorMessage: edmLoginEnabled === "true" ? "" : "dummy error message",
      }),
      ctx.delay(150)
    );
  }
);

const mockGetDTStatusWithGlobalId = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/status/drug-test/candidateGlobalId/${MOCK_ENV_CANDIDATE_GLOBAL_ID}/businessLine/AGL`,
  (req, res, ctx) => {
    return res(ctx.json({ status: StatusEnum.COMPLETED }), ctx.delay(150));
  }
);

const mockGetDTStatusWithSalesForceCandidateId = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/status/drug-test/candidate/${MOCK_ENV_CANDIDATE_SF_CANDIDATE_ID}/businessLine/AGL`,
  (req, res, ctx) => {
    return res(ctx.json({ status: StatusEnum.ACTION_REQUIRED }), ctx.delay(150));
  }
);

const mockBackgroundCheck = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/status/background-check/candidate/${MOCK_ENV_CANDIDATE_ID}/${MOCK_ENV_APPLICATION_ID}`,
  (req, res, ctx) => {
    return res(ctx.json({ status: StatusEnum.IN_PROGRESS }), ctx.delay(150));
  }
);

const mockMedicalCheck = rest.get(
  `${MOCK_ENV_API_URL_PREFIX}/status/medical-check/candidate/${MOCK_ENV_CANDIDATE_ID}`,
  (req, res, ctx) => {
    return res(ctx.json({ status: StatusEnum.COMPLETED }), ctx.delay(150));
  }
);

// eslint-disable-next-line import/no-unused-modules
export const handlers = [
  mockGetNewHireVideo,
  mockGetCsrfToken,
  mockPutCsrfToken,
  mockGetCandidateData,
  mockGetScheduleDetailsSF,
  mockGetScheduleDetailsNonSF,
  mockGetScheduleDetailsForSFServiceFailure,
  mockGetScheduleDetailsForSFParseFailure,
  mockCaasRTWStatus,
  mockGetApplicationManageDataNonSF,
  mockGetApplicationManageDataSF,
  mockGetApplicationManageDataForSFServiceFailure,
  mockAllContigencies,
  mockPutCaasI9,
  mockNHEAppointmentV2,
  mockDTAppointment,
  mockJobPreviewVideoDetails,
  mockRTWAppointmentV2,
  mockGetCandidateDataAtoZWithoutPassword,
  mockGetCandidateDataAtoZWithPassword,
  mockGetOnbaseDocumentStatus,
  mockGetDTStatusWithGlobalId,
  mockGetDTStatusWithSalesForceCandidateId,
  mockGetAppConfig,
  mockBackgroundCheck,
  mockMedicalCheck,
  mockGetCaasI9,
  mockAllContigenciesStatus,
  mockFallbackCall,
  // ============  apiSlice endpoints - GET ==============//
  rest.get(`${MOCK_ENV_API_URL_PREFIX}/getAppConfig/getASHConfig`, (req, res, ctx) => {
    return res(ctx.json("getASHConfig"), ctx.delay(150));
  }),
  rest.get(`${MOCK_ENV_API_URL_PREFIX}/jobcontainer/schedule/applicationId/locale`, (req, res, ctx) => {
    return res(ctx.json("useGetJobDetailsQuery"), ctx.delay(150));
  }),
  rest.get(`${MOCK_ENV_API_URL_PREFIX}/salesforce/jobdetails/applicationId/locale`, (req, res, ctx) => {
    return res(ctx.json("useGetJobDetailsQuery"), ctx.delay(150));
  }),
  rest.get(`${MOCK_ENV_API_URL_PREFIX}/nhe/appointment-v2/applicationId/applicationType`, (req, res, ctx) => {
    return res(ctx.json("useGetAppointmentV2Query"), ctx.delay(150));
  }),
  rest.get(`${MOCK_ENV_API_URL_PREFIX}/application/application-manage-data/applicationId/locale`, (req, res, ctx) => {
    return res(ctx.json("useGetApplicationManageDataQuery"), ctx.delay(150));
  }),
  rest.get(`api/cds/candidate/applicationId`, (req, res, ctx) => {
    return res(ctx.json("useGetCandidateDataQuery"), ctx.delay(150));
  }),
  rest.get(`api/cds/candidate/applicationId`, (req, res, ctx) => {
    return res(ctx.json("useGetCandidateDataQuery"), ctx.delay(150));
  }),
  rest.get(
    `${MOCK_ENV_API_URL_PREFIX}/atoz/candidateGlobalId/applicationId/locale/returnResetPasswordUrl`,
    (req, res, ctx) => {
      return res(ctx.json("useGetCandidateAtoZStatusQuery"), ctx.delay(150));
    }
  ),
  rest.get(
    `${MOCK_ENV_API_URL_PREFIX}/document-status/candidateGlobalId/applicationId/Salesforce/False`,
    (req, res, ctx) => {
      return res(ctx.json("useGetDocumentStatusInfoQuery"), ctx.delay(150));
    }
  ),
  rest.get(`${MOCK_ENV_API_URL_PREFIX}/status/right-to-work/candidate/candidateId`, (req, res, ctx) => {
    return res(ctx.json("useGetCaasRTWStatusQuery"), ctx.delay(150));
  }),
  rest.get("${MOCK_ENV_API_URL_PREFIX}/caas/candidateId", (req, res, ctx) => {
    const redirectUrl = req.url.searchParams.get("redirectUrl");
    return res(ctx.json("useGetCaasInfoQuery"));
  }),
  rest.get(`${MOCK_ENV_API_URL_PREFIX}/status/medical-check/candidate/candidateId`, (req, res, ctx) => {
    return res(ctx.json("useGetMcStatusQuery"), ctx.delay(150));
  }),
  rest.get(
    `${MOCK_ENV_API_URL_PREFIX}/status/drug-test/candidateGlobalId/${MOCK_ENV_CANDIDATE_GLOBAL_ID}/businessLine/businessLine`,
    (req, res, ctx) => {
      return res(ctx.json("useGetDtStatusQuery"), ctx.delay(150));
    }
  ),
  rest.get(`${MOCK_ENV_API_URL_PREFIX}/status/drug-test/application/applicationId`, (req, res, ctx) => {
    return res(ctx.json("useGetCaasDtStatusQuery"), ctx.delay(150));
  }),
  rest.get(
    `${MOCK_ENV_API_URL_PREFIX}/status/background-check/air/candidate/candidateId/applicationId`,
    (req, res, ctx) => {
      return res(ctx.json("useGetBgcStatusQuery"), ctx.delay(150));
    }
  ),

  // ============  apiSlice endpoints - POST ==============//
  rest.post(`${MOCK_ENV_API_URL_PREFIX}/withdraw-application/applicationId`, (req, res, ctx) => {
    return res(
      // Respond with a 200 status code
      ctx.status(200),
      ctx.json("useWithDrawApplicationMutation")
    );
  }),
  rest.post(`${MOCK_ENV_API_URL_PREFIX}/edm/commitUpload/candidateGlobalId/applicationId`, (req, res, ctx) => {
    return res(
      // Respond with a 200 status code
      ctx.status(200),
      ctx.json("useCommitDocumentsMutation")
    );
  }),
  rest.post(`${MOCK_ENV_API_URL_PREFIX}/edm/documents`, (req, res, ctx) => {
    return res(
      // Respond with a 200 status code
      ctx.status(200),
      ctx.json("usePreassignedLinkMutation")
    );
  }),
  rest.post(`${MOCK_ENV_API_URL_PREFIX}/cds/documentUpload/candidateId/applicationId`, (req, res, ctx) => {
    return res(
      // Respond with a 200 status code
      ctx.status(200),
      ctx.json("useGetPresignedURLToCDSMutation")
    );
  }),
  rest.patch(`${MOCK_ENV_API_URL_PREFIX}/cds/documentUpload/candidateId/applicationId`, (req, res, ctx) => {
    return res(
      // Respond with a 200 status code
      ctx.status(200),
      ctx.json("useUpdateCDSDocumentUploadMetadataMutation")
    );
  }),
  rest.post(`${MOCK_ENV_API_URL_PREFIX}/test/presignedURLtoCDS`, (req, res, ctx) => {
    return res(
      // Respond with a 200 status code
      ctx.status(200),
      ctx.json("useSubmitDocToCDSFromPresignedURLMutation")
    );
  }),
  rest.post(`/api/cds/candidate/candidateId`, (req, res, ctx) => {
    return res(
      // Respond with a 200 status code
      ctx.status(200),
      ctx.json("useUpdateCandidateDocumentIdToCDSMutation")
    );
  }),
];

// Add any permanent in-browser mocks here
const permanentBrowserHandlers = [
  // RUM javascript client is dumb and rips out the port from the URL, so we need to override it on local browser
  rest.all(`${MOCK_ENV_API_URL_PREFIX}/rum-proxy/*`, async (req, res, ctx) => {
    req.url.port = "9000";
    const realResponse = await ctx.fetch(req);
    return res(realResponse ? ctx.json(realResponse) : ctx.status(204));
  }),
];
// browserHandlers are responsible for mocking api request in browser for development purpose, remove when we want to get data from real API instead. -- Use absolute URL for the endpoints.
/**
 * For doc upload development purpose, use these endpoints as mock data:
 * http://localhost:3000/checklist/tasks/upload-documents/JOB-UK-0000003172/df686517-a8da-44f7-a53f-82d8fe74d5a3
 * http://localhost:3000/checklist/JOB-UK-0000003172/df686517-a8da-44f7-a53f-82d8fe74d5a3
 * TODO: Remove when integration is done
 **/
const tempMocks: RestHandler<MockedRequest<DefaultBodyType>>[] = [
  // Add any temporary in-browser mocks here
];

export const browserHandlers = permanentBrowserHandlers.concat(tempMocks);
